@import url(https://fonts.googleapis.com/css?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(252,232,236);
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgb(252,232,236);
}

.container{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  background-color: RGB(13, 69, 116);
  width: 100%;
  height:60px; 
  border-bottom:5px solid RGB(254, 151, 144);
}

.logo {
  margin: auto;
  color: RGB(248, 211, 218);
  font-family: 'Pacifico', cursive;
  font-size: 30px;
}

.homeName {
  text-decoration: none;   
}

footer {
  width: 100%; 
  background-color: RGBA(222, 91, 109, 0.7); 
  padding: 5px;
  text-align: center;
}

.menuContainer {
    position: fixed;
    top: 20px;
    left: 0;
    width: 99vw;
    display: flex;
    flex-direction: column;
    background-color: RGB(248, 211, 218);
    opacity: 0.95;
    color: RGB(1, 33, 71);
    transition: height 0.3s ease;
    z-index: 2;
}

.menuList {
    padding-top: 3rem;
}
.menuDiv {
    margin-top: 5px;
    margin-bottom: 5px;
}

.menuItem {
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
    color: RGB(13, 69, 116);
}

.menuItem:hover {
    font-size: 30px;
}

.line {
    width: 90%;
    height: 1px;
    background: RGB(13, 69, 116);
    margin: 0 auto;
}
.Home {
    display: flex; 
    flex-direction: column;
    background-color: rgb(252,232,236);
    font-family: 'Raleway', sans-serif;
}

.introImage {
    display: none;
    padding-top: 55px;
}

.headshot {
    width: 95%;
    margin: auto;
    margin-top: 10px;
    padding-top: 65px;
}  

.headline {
    text-align: center;
}

.katyImage {
    display: none;
}    

.bio {
    display: none;
}

.sectionHead {
    margin: 15px;
}

.fiberFrenzy {
    display: block;
    width: 425px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.projectInfo {
    margin-left: 15px;
    margin-right: 15px;
}

.gitLinks {
    text-align: center;
}

.pageLink:link {
    color: RGB(13, 69, 116);
}
  
.pageLink:visited {
    color: RGB(250, 83, 90);
}
  
.pageLink:hover {
    color: RGB(254, 151, 144);
}
  
.pageLink:active {
    color: RGB(154, 224, 213);
}

@media screen and (min-width: 768px) {
    .introImage {
        display: block;
        width: 100%;
        margin-top: 20px;
    }

    .headshot {
        display: none;
    }

    .bio {
        display: block;
        font-size: 16px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .projects {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }  

    .fiberFrenzy {
        display: block;
        width: 90%;
        margin-left: 15px;
    }

    .projectInfo {
        display: flex;
        flex-direction: column;
        align-content: center;
        width: 300px;
    }
}

.projectTech {
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: center;
}

.TechLogo {
    display: block;
    width: 30px;   
} 
.AboutMe {
    display: flex; 
    flex-direction: column;
    background-color: rgb(252,232,236);
    margin-bottom: 30px;
}

.aboutImage {
    margin: auto;
}

.bioGreeting {
    font-family: 'pacifico', cursive;
    margin-top: 0px; 
    margin-bottom: 0px;
}

.bioPage {
    width: 350px;
    height: 466px;
    margin-left: auto; 
    margin-right: auto;
    margin-top: 75px;
} 

.bioGraph {
    margin-top: 15px;
    margin-bottom: 0px;
}

.aboutBio {
    display: block;
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Raleway', sans-serif;
}

.center {
    margin-left: auto; 
    margin-right: auto;
    margin-top: 4px; 
    margin-bottom: 0px;
    text-align: center;
}

.centerLinks {
    margin-left: auto; 
    margin-right: auto;
    margin-top: 15px; 
    margin-bottom: 0px;
    text-align: center;
}

.close {
    font-family: 'pacifico', cursive;
    align-content: flex-end;
    margin-top: 15px;
    margin-bottom: 0px;
}

.pageLink:link {
    color: RGB(13, 69, 116);
}
  
.pageLink:visited {
    color: RGB(250, 83, 90);
}
  
.pageLink:hover {
    color: RGB(254, 151, 144);
}
  
.pageLink:active {
    color: RGB(154, 224, 213);
}

@media screen and (min-width: 768px) {
    .AboutMe {
        display: flex; 
        flex-direction: row;
        margin-top: 65px;
    }

    .aboutImage {
        margin-left: 15px;
        margin-top: 0px;
    }

    .bioPage {
        margin-top: 25px;
    } 

    .bioGreeting {
        margin-top: 25px; 
        margin-bottom: 0px;
    }

    .bioGraph {
        margin-top: 15px;
        margin-bottom: 0px;
        margin-right: 15px;
    }
}
.Projects {
    display: flex; 
    flex-direction: column;
    background-color: rgb(252,232,236);
    font-family: 'Raleway', sans-serif;
    margin-top: 65px;
}

.sectionHead {
    margin-bottom: 0px;
}

.projTitle {
    margin-left: 15px;
    margin-right: 15px;
    font-weight: bold;
    font-size: 20px;
}

.projImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    margin-top: 0px;
    width: 90%;
}

.projInfo {
    margin-left: 15px;
    margin-right: 15px;
}

.projLinks {
    text-align: center;
}

.pageLink:link {
    color: RGB(13, 69, 116);
}
  
.pageLink:visited {
    color: RGB(250, 83, 90);
}
  
.pageLink:hover {
    color: RGB(254, 151, 144);
}
  
.pageLink:active {
    color: RGB(154, 224, 213);
}

@media screen and (min-width: 768px) {
    .projImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        margin-top: 0px;
        width: 425px;
    }

    .projectsPage {
        display: flex;
        flex-direction: row;
        margin-left: 15px;
        margin-right: 15px;
    }

    .projInfo {
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 0px;
    }

    .thumb.selected {
        border-color: RGB(13, 69, 116);
    }

}

@media screen and (min-width: 1025px) {
    .projectsPage {
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .projTitle {
        margin: auto;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
.footer {
    left: 0;
    bottom: 0;
    width: 99%;
    background-color: rgb(154, 224, 213);
}

.footerText {
    font-family: 'Raleway', sans-serif;
    font-size: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.footerLink:link {
    color: RGB(13, 69, 116);
}
  
.footerLink:visited {
    color: RGB(250, 83, 90);
}
  
.footerLink:hover {
    color: RGB(254, 151, 144);
}
  
.footerLink:active {
    color: RGB(154, 224, 213);
}
