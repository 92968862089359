.AboutMe {
    display: flex; 
    flex-direction: column;
    background-color: rgb(252,232,236);
    margin-bottom: 30px;
}

.aboutImage {
    margin: auto;
}

.bioGreeting {
    font-family: 'pacifico', cursive;
    margin-top: 0px; 
    margin-bottom: 0px;
}

.bioPage {
    width: 350px;
    height: 466px;
    margin-left: auto; 
    margin-right: auto;
    margin-top: 75px;
} 

.bioGraph {
    margin-top: 15px;
    margin-bottom: 0px;
}

.aboutBio {
    display: block;
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Raleway', sans-serif;
}

.center {
    margin-left: auto; 
    margin-right: auto;
    margin-top: 4px; 
    margin-bottom: 0px;
    text-align: center;
}

.centerLinks {
    margin-left: auto; 
    margin-right: auto;
    margin-top: 15px; 
    margin-bottom: 0px;
    text-align: center;
}

.close {
    font-family: 'pacifico', cursive;
    align-content: flex-end;
    margin-top: 15px;
    margin-bottom: 0px;
}

.pageLink:link {
    color: RGB(13, 69, 116);
}
  
.pageLink:visited {
    color: RGB(250, 83, 90);
}
  
.pageLink:hover {
    color: RGB(254, 151, 144);
}
  
.pageLink:active {
    color: RGB(154, 224, 213);
}

@media screen and (min-width: 768px) {
    .AboutMe {
        display: flex; 
        flex-direction: row;
        margin-top: 65px;
    }

    .aboutImage {
        margin-left: 15px;
        margin-top: 0px;
    }

    .bioPage {
        margin-top: 25px;
    } 

    .bioGreeting {
        margin-top: 25px; 
        margin-bottom: 0px;
    }

    .bioGraph {
        margin-top: 15px;
        margin-bottom: 0px;
        margin-right: 15px;
    }
}