.footer {
    left: 0;
    bottom: 0;
    width: 99%;
    background-color: rgb(154, 224, 213);
}

.footerText {
    font-family: 'Raleway', sans-serif;
    font-size: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.footerLink:link {
    color: RGB(13, 69, 116);
}
  
.footerLink:visited {
    color: RGB(250, 83, 90);
}
  
.footerLink:hover {
    color: RGB(254, 151, 144);
}
  
.footerLink:active {
    color: RGB(154, 224, 213);
}