.Home {
    display: flex; 
    flex-direction: column;
    background-color: rgb(252,232,236);
    font-family: 'Raleway', sans-serif;
}

.introImage {
    display: none;
    padding-top: 55px;
}

.headshot {
    width: 95%;
    margin: auto;
    margin-top: 10px;
    padding-top: 65px;
}  

.headline {
    text-align: center;
}

.katyImage {
    display: none;
}    

.bio {
    display: none;
}

.sectionHead {
    margin: 15px;
}

.fiberFrenzy {
    display: block;
    width: 425px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.projectInfo {
    margin-left: 15px;
    margin-right: 15px;
}

.gitLinks {
    text-align: center;
}

.pageLink:link {
    color: RGB(13, 69, 116);
}
  
.pageLink:visited {
    color: RGB(250, 83, 90);
}
  
.pageLink:hover {
    color: RGB(254, 151, 144);
}
  
.pageLink:active {
    color: RGB(154, 224, 213);
}

@media screen and (min-width: 768px) {
    .introImage {
        display: block;
        width: 100%;
        margin-top: 20px;
    }

    .headshot {
        display: none;
    }

    .bio {
        display: block;
        font-size: 16px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .projects {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }  

    .fiberFrenzy {
        display: block;
        width: 90%;
        margin-left: 15px;
    }

    .projectInfo {
        display: flex;
        flex-direction: column;
        align-content: center;
        width: 300px;
    }
}
